(function ($) {
  $(document).on('product.init', '.js-product', function () {
    var $imageContainer = $('.product-full__image', $(this));

    if ($imageContainer.length) {
      Drupal.behaviors.productFullImageV1.attach($(this));
    }
  });

  Drupal.behaviors.productFullImageV1 = {
    attach: function (context) {
      // images
      var $containers = $('.product-full__image');

      $containers.each(function () {
        var $container = $(this);
        var $sppImages = $('.product-full__image-carousel', $container);
        var $carousel = $('.js-spp-carousel', $sppImages);
        var $fullscreenTrigger = $('.product-full__image__fullscreen-trigger', $container);
        var $fullscreenClose = $('.product-full__image__fullscreen-close', $container);
        var $flagContainer = $('.product-flag__container', context);
        var arrowsDiv = $('.carousel-controls', $container);
        var dotsDiv = $('.carousel-dots', $container);
        var sppSlide = '.js-spp-carousel__slide';

        $([arrowsDiv, dotsDiv]).each(function () {
          $(this).on('click', function () {
            productBadgeDisplay();
          });
        });

        // case for custom slides count and dots for mobile
        var settings = {
          slide: sppSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          appendArrows: arrowsDiv,
          appendDots: dotsDiv,
          fade: false
        };
        var fullscreen_settings = {
          slide: sppSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          fade: true
        };
        var init_default_carousel = function () {
          // Init this carousel with our settings
          $carousel.not('.slick-initialized').slick(settings);

          $(document).trigger('product.carousel-loaded');

          // Update the thumbnails to highlight the correct thumbnail
          $carousel.on('afterChange', function (event, slick, currentSlide) {
            productBadgeDisplay();
            var $sppThumbnails = $(
              '.product-full__image-thumbnails',
              slick.$slider.parents('.product-full__image')
            );
            var $thumbWrapper = $('.product-full__carousel__thumbnail', $sppThumbnails);

            $thumbWrapper.removeClass('active'); // Remove other active states
            $thumbWrapper.eq(currentSlide).addClass('active'); // Add the active state to this item
            $(this).data('currentSlide', currentSlide);
          });
        };
        var productBadgeDisplay = function () {
          var sliderIndex = $carousel.find('.slick-current').attr('data-slick-index');

          $flagContainer.hide();
          if (sliderIndex === '0') {
            $flagContainer.show();
          }
        };

        init_default_carousel();

        $(document).on('product_full.fullscreen_viewer', function () {
          var currentSlide = $carousel.slick('slickCurrentSlide');

          fullscreen_settings['initialSlide'] = currentSlide;
          $container.addClass('fullscreen-enabled');
          $('body').addClass('fullscreen-enabled');
          $carousel.slick('unslick');
          $carousel.slick(fullscreen_settings);
        });

        $fullscreenClose.click(function () {
          var currentSlide = $carousel.slick('slickCurrentSlide');

          settings['initialSlide'] = currentSlide;

          $carousel.slick('unslick');
          $container.removeClass('fullscreen-enabled');
          $('body').removeClass('fullscreen-enabled');
          $carousel.slick(settings);
        });

        // thumbnails
        var $sppThumbnails = $('.product-full__image-thumbnails', $container);

        $sppThumbnails.each(function () {
          var $thisFormatter = $(this);
          var $thumbWrapper = $('.product-full__carousel__thumbnail', $thisFormatter);

          $thumbWrapper.first().addClass('active'); // Set the first as active on load
          var $thumbnailImage = $('.product-full__carousel__thumbnail-img', $thumbWrapper);

          $thumbnailImage.on('click', function () {
            var currentSlide = $thumbWrapper.index($(this).parents($thumbWrapper));

            $carousel.slick('slickGoTo', currentSlide);
            $thumbWrapper.removeClass('active'); // Remove other active states
            $thumbWrapper.eq(currentSlide).addClass('active'); // Add the active state to this item
          });
        });

        $(document).on('quickshop.loaded', function () {
          $carousel.slick('setPosition');
        });
      });
    }
  };
})(jQuery);
